import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AccountsReceivableClient,
  AssignToUserViewModel,
  ClosedPaymentExceptionFilterBy,
  ClosedPaymentExceptionFilterByFilterBy,
  ClosedPaymentExceptionSortBy,
  ClosedPaymentExceptionSortBySortBy,
  FindPaymentExceptionsResponse,
  UsersClient,
} from "src/app/apis/customer-care-api.generated";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

@Component({
  selector: "app-closed-exception-list",
  templateUrl: "./closed-exception-list.component.html",
  styleUrls: ["./closed-exception-list.component.scss"],
})
export class ClosedExceptionListComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  isLoading: boolean;
  totalRecords = 0;
  pageIndex: number;
  pageSize: number;
  isInitialized: boolean;

  sortBy: ClosedPaymentExceptionSortBySortBy[] = [];
    filterBy: ClosedPaymentExceptionFilterByFilterBy[] = [];
  

   filterFormGroup = new FormGroup({
      id: new FormControl(null),
      accountNumber: new FormControl(null),
      assigneeId: new FormControl(null)
    });

    assigneeList: AssignToUserViewModel[];

    hasValues = false;

    isAssigneeListLoading: boolean;

  records: FindPaymentExceptionsResponse[] = [];

  constructor(
    private accountsReceivableClient: AccountsReceivableClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private userClient: UsersClient
  ) { }

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;

    var hasValues = false;

    // if there are values in the query params, set the form values
    this.route.queryParams.subscribe((params) => {
      if (params.accountNumber) {
        this.filterFormGroup.patchValue({
          accountNumber: params.accountNumber,
        });
        hasValues = true;
      }
      if (params.assigneeId) {
        this.filterFormGroup.patchValue({
          assigneeId: params.assigneeId,
        });
        hasValues = true;
      }
      if (params.pageSize) {
        this.pageSize = params.pageSize;
      }
      if (params.pageIndex) {
        this.pageIndex = params.pageIndex;
      }
    });
    this.getAssigneeList();
    if(hasValues)
    {
      this.loadPaymentExceptions();
    }
  }

  getAssigneeList() {

    this.isAssigneeListLoading = true;
    this.subscription.add(
      this.userClient.getAssignableUsers().subscribe(response => {
        this.assigneeList = response.result;
        this.isAssigneeListLoading = false;
      },
        err => {
          this.snackBarService.ShowError('Fetching Assignee List Error: ' + err);
        })
    );
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      accountNumber: null,
      assigneeId: null,
    });
  }

  getAssigneeId = (assignee: AssignToUserViewModel) => assignee.id;
    getAssigneeName = (assignee: AssignToUserViewModel) => assignee.name === null ? '' : assignee.name;

  validateSearch() {
    if (this.filterFormGroup.value.accountNumber && this.filterFormGroup.value.accountNumber != 0) {
      return true;
    }
    if (this.filterFormGroup.value.assigneeId && this.filterFormGroup.value.assigneeId != "") {
      return true;
    }
    return false;
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadPaymentExceptions();
  }


  loadPaymentExceptions() {
    this.isLoading = true;
    var isValidated = this.validateSearch();
    if (!isValidated) {
      this.isLoading = false;
      this.snackBarService.ShowError("Please enter a value for either Account Number or Created By.");
      return;
    }
    this.updateFilters();
    this.subscription.add(
      this.accountsReceivableClient.closedPaymentExceptionsSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.hasValues = true;
          this.records = result.records;
          this.totalRecords = result.totals[0].value;
          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters() {
      // Default Sort By
      this.sortBy = [
        new ClosedPaymentExceptionSortBySortBy({
          property: ClosedPaymentExceptionSortBy.Id,
          descendingOrder: true,
        }),
      ];
  
      this.filterBy = [];
      // Id
      if (
        this.filterFormGroup.value.accountNumber &&
        this.filterFormGroup.value.accountNumber != 0
      ) {
        this.filterBy.push(
          new ClosedPaymentExceptionFilterByFilterBy({
            property: ClosedPaymentExceptionFilterBy.ConfirmationCode,
            equal: this.filterFormGroup.value.accountNumber
          })
        );
      }
      // Created By User
      if (
        this.filterFormGroup.value.assigneeId &&
        this.filterFormGroup.value.assigneeId != "" &&
        this.filterFormGroup.value.assigneeId != null
      ) {
        this.filterBy.push(
          new ClosedPaymentExceptionFilterByFilterBy({
            property: ClosedPaymentExceptionFilterBy.CreatedBy,
            equal: this.filterFormGroup.value.assigneeId
          })
        );
      }
  
    }

  paginate(e: ICommonPaginatorEvent): void {
      if (this.isInitialized) {
        if (this.pageIndex == e.pageIndex) {
          this.pageIndex = 1;
        } else {
          this.pageIndex = e.pageIndex;
        }
        this.pageSize = e.pageSize;
        this.loadPaymentExceptions();
      }
    }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
