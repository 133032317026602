import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AccountsReceivableClient,
  FindPaymentExceptionsResponse,
} from "src/app/apis/customer-care-api.generated";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

@Component({
  selector: "app-exception-list",
  templateUrl: "./exception-list.component.html",
  styleUrls: ["./exception-list.component.scss"],
})
export class ExceptionListComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  isLoading: boolean;
  totalRecords = 0;
  pageIndex: number;
  pageSize: number;
  isInitialized: boolean;

  filterFormGroup = new FormGroup({
        id: new FormControl(null),
        confirmationCode: new FormControl(null),
      });

  records: FindPaymentExceptionsResponse[] = [];

  constructor(
    private accountsReceivableClient: AccountsReceivableClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;

    this.route.queryParams.subscribe((params) => {
      if (params.confirmationCode) {
        this.filterFormGroup.patchValue({
          confirmationCode: params.confirmationCode,
        });
      }
      if (params.pageSize) {
        this.pageSize = params.pageSize;
      }
      if (params.pageIndex) {
        this.pageIndex = params.pageIndex;
      }
    });

    this.loadPaymentExceptions();
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      confirmationCode: null,
    });
    this.loadPaymentExceptions();
  }

  
  validateSearch() {
    if (this.filterFormGroup.value.confirmationCode && this.filterFormGroup.value.confirmationCode != 0) {
      return true;
    }
    return false;
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadPaymentExceptions();
  }

  loadPaymentExceptions() {
    this.isLoading = true;

    // var isValidated = this.validateSearch();
    // if (!isValidated) {
    //   this.isLoading = false;
    //   this.snackBarService.ShowError("Please enter a value for Confirmation Code.");
    //   return;
    // }

    this.subscription.add(
      this.accountsReceivableClient.allPaymentExceptions(
        this.pageIndex, this.pageSize, this.filterFormGroup.value.confirmationCode
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.records = result.result.paymentExceptions;
          this.totalRecords = result.result.totalRecords;
          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  paginate(e: ICommonPaginatorEvent): void {
      if (this.isInitialized) {
        if (this.pageIndex == e.pageIndex) {
          this.pageIndex = 1;
        } else {
          this.pageIndex = e.pageIndex;
        }
        this.pageSize = e.pageSize;
        this.loadPaymentExceptions();
      }
    }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
