import { Injectable, OnDestroy } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import {
  CurrentUserViewModel,
  LookupClient
} from "src/app/apis/customer-care-api.generated";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private currentUserSubject: BehaviorSubject<CurrentUserViewModel> = new BehaviorSubject<CurrentUserViewModel>(null);
  public currentUser = this.currentUserSubject.asObservable();
  private currentUserInfo: any;

  constructor(private lookupClient: LookupClient,
    private router: Router,
    private msalService: MsalService) {
    if (this.getMsalAccount()) {
      if (localStorage.getItem("currentUser") == null) {
        this.lookupClient.getCurrentUserInfo().subscribe((response) => {
          localStorage.setItem("currentUser", JSON.stringify(response.result));
          this.populateCurrentUser();
        },
          (error) => {
            try {
              console.error(error);
              this.router.navigateByUrl("/error");
            }
            catch { }
          });
      } else {
        this.populateCurrentUser();
      }
    }

    this.currentUser
      .subscribe(val => {
        this.currentUserInfo = val;
      });
  }
  ngOnDestroy(): void {
    this.currentUserSubject.unsubscribe();
  }

  get currentUserName() {
    return this.currentUserInfo ?
      this.currentUserInfo.firstName + ' ' + this.currentUserInfo.lastName :
      '';
  }

  get isSystemAdmin() {
    return this.currentUserInfo ? this.currentUserInfo.isSystemAdmin : false;
  }

  get isAllowedToCreateNotification() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateNotification : false;
  }

  get isAllowedToImpersonateInMyAccount() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToImpersonateInMyAccount : false;
  }

  get isAllowedToViewRequestStats() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewRequestStats : false;
  }

  get isAllowedToViewReports() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewReports : false;
  }

  get isAllowedToEditGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditGroups : false;
  }

  get isAllowedToCreateBusinessUnit() {
    return this.currentUser ? this.currentUserInfo.isAllowedToCreateBusinessUnit : false;
  }

  get isAllowedToCreateFacility() {
    return this.currentUser ? this.currentUserInfo.isAllowedToCreateBusinessUnit : false;
  }

  get isAllowedToDeleteFusionAutoPay() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteFusionAutoPay : false;
  }

  get isAllowedToFilterByAssignee() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToFilterByAssignee : false;
  }

  get isAllowedToUseRecordSync() {
    console.log(this.currentUserInfo)
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUseRecordSync : false;
  }

  get isAllowedToViewBusinessUnits() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewBusinessUnits : false;
  }

  get isAllowedToViewFacilities() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewFacilities : false;
  }

  get isAllowedToViewAllUsers() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewAllUsers || this.currentUserInfo.isSystemAdmin : false;
  }
  get isAllowedToCreateUser() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateUser : false;
  }
  get isAllowedToViewNotifications() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewNotifications : false;
  }
  get isAllowedToTransferOwnership() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToTransferOwnership : false;
  }
  get isAllowedToMassTransferOwnership() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToMassTransferOwnership : false;
  }
  get isAllowedToUsePayMyBillValidation() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUsePayMyBillValidation : false;
  }
  get isAllowedToViewRequests() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewRequests : false;
  }
  get isAllowedToCreateMyAccountUser() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateMyAccountUser : false;
  }
  get isAllowedToCreateGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateGroups : false;
  }
  get isAllowedToViewGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewGroups : false;
  }
  get isAllowedToCreateManagedAutoPayMyAccountUser() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateManagedAutoPayMyAccountUser : false;
  }
  get isAllowedToUseAccountLookup() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUseAccountLookup : false;
  }
  get isAllowedToDeleteAzureProfile() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteAzureProfile : false;
  }
  get isAllowedToDeleteEbilling() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteEbilling : false;
  }
  get isAllowedToUseCustomerFinder() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUseCustomerFinder : false;
  }
  get isAllowedToUseSecureCheckoutLinks() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUseSecureCheckoutLinks : false;
  }

  get isAllowedToViewAccountEventLogs() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewAccountEventLogs : false;
  }

  get isAllowedToVoidPayment() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToVoidPayment : false;
  }

  get isAllowedToCloseExceptions ()
  {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCloseExceptions : false;
  }

  get isAllowedToViewARTools () {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewARTools : false;
  }

  get isAllowedToCancelFuturePayment() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCancelFuturePayment : false;
  }
  

  get isAllowedToViewErrorHandling() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewErrorHandling : false;
  }

  get isAllowedToDeleteMyAccountProfile() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteMyAccountProfile : false;
  }

  get isAllowedToUseEmailLookup() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToUseEmailLookup : false;
  }

  get isAllowedToCreatePermissionGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreatePermissionGroups : false;
  }

  get isAllowedToEditPermissionGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditPermissionGroups : false;
  }

  get isAllowedToViewPermissionGroups() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewAllPermissionGroups : false;
  }

  get isAllowedToViewEcommerceAreas() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewEcommerceAreas : false;
  }
  get isAllowedToViewEcommerceBlacklist() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewEcommerceBlacklist : false;
  }
  get isAllowedToViewEcommerceFees() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewEcommerceFees : false;
  }
  get isAllowedToViewPromoCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewPromoCodes : false;
  }
  get isAllowedToViewServiceCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewServiceCodes : false;
  }
  get isAllowedToViewServiceFrequencies() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewServiceFrequencies : false;
  }
  get isAllowedToViewBillingCycles() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewBillingCycles : false;
  }
  get isAllowedToViewServiceTypes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToViewServiceTypes : false;
  }
  get isAllowedToEditEcommerceAreas() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditEcommerceAreas : false;
  }
  get isAllowedToEditEcommerceBlacklist() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditEcommerceBlacklist : false;
  }
  get isAllowedToEditEcommerceFees() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditEcommerceFees : false;
  }
  get isAllowedToEditPromoCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditPromoCodes : false;
  }
  get isAllowedToEditServiceCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditServiceCodes : false;
  }
  get isAllowedToEditServiceFrequencies() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditServiceFrequencies : false;
  }
  get isAllowedToEditBillingCycles() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditBillingCycles : false;
  }
  get isAllowedToEditServiceTypes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToEditServiceTypes : false;
  }
  get isAllowedToDeleteEcommerceAreas() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteEcommerceAreas : false;
  }
  get isAllowedToDeleteEcommerceBlacklist() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteEcommerceBlacklist : false;
  }
  get isAllowedToDeleteEcommerceFees() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteEcommerceFees : false;
  }
  get isAllowedToDeletePromoCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeletePromoCodes : false;
  }
  get isAllowedToDeleteServiceCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteServiceCodes : false;
  }
  get isAllowedToDeleteServiceFrequencies() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteServiceFrequencies : false;
  }
  get isAllowedToDeleteBillingCycles() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteBillingCycles : false;
  }
  get isAllowedToDeleteServiceTypes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToDeleteServiceTypes : false;
  }
  get isAllowedToCreateEcommerceAreas() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateEcommerceAreas : false;
  }
  get isAllowedToCreateEcommerceBlacklist() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateEcommerceBlacklist : false;
  }
  get isAllowedToCreateEcommerceFees() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateEcommerceFees : false;
  }
  get isAllowedToCreatePromoCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreatePromoCodes : false;
  }
  get isAllowedToCreateServiceCodes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateServiceCodes : false;
  }
  get isAllowedToCreateServiceFrequencies() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateServiceFrequencies : false;
  }
  get isAllowedToCreateBillingCycles() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateBillingCycles : false;
  }
  get isAllowedToCreateServiceTypes() {
    return this.currentUserInfo ? this.currentUserInfo.isAllowedToCreateServiceTypes : false;
  }

  populateCurrentUser() {
    var currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      this.currentUserSubject.next(
        JSON.parse(currentUser)
      );
    }
  }

  clearCurrentUser() {
    localStorage.removeItem("currentUser");
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  private getMsalAccount = () => {
    try {
      return this.msalService.getAccount();
    }
    catch {
      return null;
    }
  }
}
