import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  ReportsClient,
  EventLogFilterByFilterBy,
  EventLogSortBySortBy,
  EventLogViewModel,
  EventLogSortBy,
  EventLogFilterBy,
  EventType,
  EventAction,
  EventOrigin,
  MyAccountAccountsClient,
  MyAccountLookupBy,
  MyAccountUserWithLinkDetailsViewModel,
  EventOrigin2
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { IResendEmailDialogInputData, ResendEmailDialogComponent } from "./resend-email-dialog/resend-email-dialog.component";
import { ICancelFuturePaymentDialogInputData, CancelFuturePaymentDialogComponent } from "./cancel-future-payment-dialog/cancel-future-payment-dialog.component";
import { IVoidPaymentDialogComponent, VoidPaymentDialogComponent } from "./void-payment-dialog/void-payment-dialog.component";
import { AuthService } from "src/app/shared/auth/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-events-page",
  templateUrl: "./events-page.component.html",
  styleUrls: ["./events-page.component.scss"],
})
export class EventsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isResultLoading: boolean;
  hasValues: boolean;
  totalRecords: number;

  @Input() accountNumber: string;
  @Input() showFilter: boolean = true;
  @Input() searchByRequest: boolean = false;
  @Input() confirmationCode: string = "";

  toolDescription = "This tool is meant to find errors related to an account. \n It shows all actions/events that have occurred for a specific account number during a specific time period. \n By default it shows the results in descending order, but this can be changed."

  selectedIds: string[] = [];
  private isInitialized: boolean;

  selectedReport: string;

  checkAccountNumber = false;

  dateFormGroup = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    orderBy: new FormControl(null)
  });

  filterFormGroup = new FormGroup({
    accountNumber: new FormControl(null),
    selectedRequestType: new FormControl(null),
    selectedEventOrigin: new FormControl(null),
    confirmationCode: new FormControl(null),
    paymentAmount: new FormControl(null),
  });

  requestType = new FormControl([]);

  events: any[];
  isLoading: boolean;
  isResendEmailLoading: boolean = false; 

  public pageIndex: number;
  public pageSize: number;
  filterBy: EventLogFilterByFilterBy[] = [];
  sortBy: EventLogSortBySortBy[] = [];
  sortControl = new FormControl('desc'); // Default to "Date: New to Old"
  requests: EventLogViewModel[];

  eventActions = Object.values(EventAction);
  eventTypes = Object.values(EventType);
  eventOrigins = Object.values(EventOrigin2);

  expandedEventId: number | null = null;
  usersFromAccount: MyAccountUserWithLinkDetailsViewModel[] = [];

  userIsAllowedToCancelFuturePayments = false;
  userIsAllowedToVoidPayments = false;

  emails: string[] = [];

  toggleExpansion(eventId: number) {
    this.expandedEventId = this.expandedEventId === eventId ? null : eventId;
  }

  constructor(
    private reportClient: ReportsClient,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBarService: SnackBarService,
    private myAccountAccountsClient: MyAccountAccountsClient,
    private authService: AuthService,
    private translate : TranslateService
  ) {
    this.customSearchFn = this.customSearchFn.bind(this);
   }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.userIsAllowedToCancelFuturePayments = response.isAllowedToCancelFuturePayment;
        this.userIsAllowedToVoidPayments = response.isAllowedToVoidPayment;
      }
    );
    // set form group confirmation code to be confirmation code from input
    if(this.confirmationCode != null && this.confirmationCode.length > 0)
    { 
      this.filterFormGroup.patchValue({
        confirmationCode: this.confirmationCode
      });
    }
    
    if(this.accountNumber == "0")
    {
      this.checkAccountNumber = true;
    }

    this.pageIndex = 1;
    this.pageSize = 10;
    this.initializeSortBy();
    this.filterRequests();
  }

  copyUserEmail(event: EventLogViewModel)
  {
    let data = ("");
    
    if (event.csrPortalUserUniqueId) 
    {
      data = (event.csrPortalUserEmail);
    } 
    else if (event.impersonatedUserUniqueId) {
      if(event.csrName && event.csrName.length > 0)
      {
        // if we have both emails, we should copy the user email
        data = (event.myAccountUserEmail + " " + this.translate.instant("impersonating") + " " + event.impersonatedUserEmail);
      }
    } else if (event.myAccountUserUniqueId) {
      data = (event.myAccountUserEmail);
    } 
    else
    {
      return;
    }
    navigator.clipboard.writeText(data).then(
      success => this.snackBarService.ShowSuccess("Successfully copied")
    ).catch(
      e => this.snackBarService.ShowError("There was an issue while copying")
    );
  }

  formatOccurredAt(event: any): string {
    if (!event.occurredAt) {
      return '-';
    }
    const date = new Date(event.occurredAt);
    if (event.wasMigrated) {
      return date.toLocaleString('en-US', { timeZone: 'UTC', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    } else {
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    }
  }

  initializeSortBy(): void {
    const sortByDaysOld = new EventLogSortBySortBy({
      property: EventLogSortBy.OccurredAt,
      descendingOrder: this.sortControl.value === 'desc',
    });

    this.sortBy.push(sortByDaysOld);
  }

  onSortChange(sortOrder: string): void {
    this.sortBy = []; // Clear the existing sortBy array

    const sortByDaysOld = new EventLogSortBySortBy({
      property: EventLogSortBy.OccurredAt,
      descendingOrder: sortOrder === 'desc',
    });

    this.sortBy.push(sortByDaysOld);
    this.filterRequests();
  }

  getKeys(detailsJson: string): string[] {
    if (detailsJson === null) {
      return [];
    }
    const details = JSON.parse(detailsJson);
    return Object.keys(details);
  }
  navigateToPaymentLink(value: string) {
    this.router.navigate([`/payment-link/view/${value}`]);
  }

  getValue(detailsJson: string, key: string): string {
    if (detailsJson === null) {
      return "";
    }
    const details = JSON.parse(detailsJson);
    return details[key];
  }

  customSearchFn(term: string, item: string): boolean {
    term = term.toLowerCase();
    try{
      let translatedItem = this.translate.instant(item);
      if (translatedItem === item) {
        translatedItem = item;
      }
      return translatedItem.toLowerCase().indexOf(term) > -1;
    }
    catch(e)
    {
      console.log(e);
    }
    return false;
    
    
  }

  resendEmail(event: EventLogViewModel) {
    this.isResendEmailLoading = true;

    var emails = [];

    this.subscription.add(
      this.reportClient
        .searchEmailsForPayment(
          event.id
        )
        .subscribe(
          (result) => {
            if (result.isSuccess) {
              emails = result.result.emailAddresses;
              const dialogConfig = new MatDialogConfig();

              dialogConfig.autoFocus = false;
              dialogConfig.disableClose = false;
              dialogConfig.width = "600px";
              dialogConfig.maxHeight = "800px";

              const data: IResendEmailDialogInputData = {
                eventId: event.id,
                emails: result.result.emailAddresses
              };

              dialogConfig.data = data;
              this.subscription.add(
                this.dialog
                  .open(ResendEmailDialogComponent, dialogConfig)
                  .afterClosed()
                  .subscribe((response) => {
                    if (response) {
                  
                      this.isResendEmailLoading = false;
                      this.snackBarService.ShowSuccess(this.translate.instant('email_successful'));
                      this.filterRequests();
                    }
                  })
              );
              this.isResendEmailLoading = false;
            }
            // setTimeout(() => {
            //   this.isInitialized = true;
            // }, 2000);
          },
          (error) => {
            console.error(error);
            this.isResendEmailLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );

    this.isResendEmailLoading = false;

  }
  canResendEmail(event: EventLogViewModel) {
    if(event.eventType == EventType.FirstechTransaction)
    {
      return false;
    }
    return event.eventAction == EventAction.SuccessfulPayment || event.eventAction == EventAction.CSRVoidPayment ||event.eventAction == EventAction.UnsuccessfulPayment || event.eventAction == EventAction.UserPayment  || event.eventAction == EventAction.FuturePaymentScheduled || event.eventAction == EventAction.EcommercePayment || event.eventAction == EventAction.EmailNotificationNotSent || event.eventAction == EventAction.EmailNotificationSent;
  }

  isAllowedToCancel(event: EventLogViewModel) {
    var result = event.eventAction == EventAction.FuturePaymentScheduled && !event.isFutureDatedPaymentCancelled && !event.isFutureDatedPaymentCompleted;
    
    if(result)
    {
      // if we can cancel, check if the scheduled date has passed
      // get the eventDetails, and find the key with value Scheduled Date
      // then try to get the value of this key, and then convert it to a date from a string in this format: dd-MM-yyyy
      // if that date is before today, return true, else return false
      var details = JSON.parse(event.detailsJson);
      var scheduledDate = details["Scheduled Date"];
      var scheduledDateObj = this.parseDate(scheduledDate);
      var now = new Date();
      if(scheduledDateObj > now)
      {
        return true;
      }
      else
      {
        return false;
      }

    }
    
    return result;
  }

  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-based in JavaScript Date
  }

  isNotAllowedToVoidAnymore(event: EventLogViewModel) {
    // this event could have been voided, but it is now passed the deadline
    if((event.eventAction == EventAction.SuccessfulPayment || event.eventAction == EventAction.EcommercePayment) && !this.isAllowedToVoid(event))
    {
      return true;
    }
  }

  isAllowedToVoid(event: EventLogViewModel) {
    if(event.eventType == EventType.FirstechTransaction)
      {
        return false;
      }
    if (event.isFutureDatedPaymentCancelled) {
      return false;
    }
    if (event.eventAction == EventAction.SuccessfulPayment || event.eventAction == EventAction.EcommercePayment) {
      const now = new Date();
      const eventDate = new Date(event.occurredAt);
    
      // Convert both dates to EST
      const estFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
    
      const nowInEST = new Date(estFormatter.format(now));
      const eventDateInEST = new Date(estFormatter.format(eventDate));
    
      const eventDay = eventDateInEST.toDateString();
      const nowDay = nowInEST.toDateString();
      const eventHour = eventDateInEST.getHours();
      const nowHour = nowInEST.getHours();
    
      if (eventDay === nowDay) {
        // Payment was made today
        if (eventHour < 20 && nowHour < 20) {
          // Payment was made before 8pm and current time is before 8pm
          return true;
        } 
        if (eventHour >= 20 && nowHour >= 20) {
          // Payment was made after 8pm and current time is after 8pm
          return true;
        } 
      } 
      else {
        // check if the payment was made yesterday
        const yesterday = new Date(nowInEST);
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayDay = yesterday.toDateString();
        if (eventDay === yesterdayDay) {
          // if it was made yesterday and before 8pm, then return false
          if (eventHour < 20) {
            return false;
          }
          // if it was made yesterday and after 8pm,
          // then check that the current time of today is before 8pm
          // if it is before 8pm, then return true, if it is after, then return false
          else if (eventHour >= 20 && nowHour < 20) {
            // Payment was made yesterday after 8pm and current time is before 8pm today
            return true;
          }
        }
      }
    }
    return false;
  }

  cancelFuturePayment(event: EventLogViewModel) {
    this.isLoading = true;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";

    const data: ICancelFuturePaymentDialogInputData = {
      event : event
    };

    dialogConfig.data = data;
    this.subscription.add(
      this.dialog
        .open(CancelFuturePaymentDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.isLoading = false;
            this.snackBarService.ShowSuccess(this.translate.instant('payment_cancelled'));
            event.isFutureDatedPaymentCancelled = true;
            this.filterRequests();
          }
        })
    );
    this.isLoading = false;
  }

  voidPayment(event: EventLogViewModel) {
    this.isLoading = true;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";

    const data: IVoidPaymentDialogComponent = {
      event: event
    };

    dialogConfig.data = data;
    this.subscription.add(
      this.dialog
        .open(VoidPaymentDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.isLoading = false;
            this.snackBarService.ShowSuccess(this.translate.instant('payment_cancelled'));
            event.isFutureDatedPaymentCancelled = true;
            this.filterRequests();
          }
        })
    );
    this.isLoading = false;
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
      this.filterRequests();
    }
  }

  loadEventTypes() {
    this.isLoading = true;

    this.subscription.add(
      this.reportClient
        .eventLogListSearch(
          this.pageIndex,
          this.pageSize,
          this.sortBy,
          this.filterBy.length == 0 ? undefined : this.filterBy
        )
        .subscribe(
          (result) => {
            if(this.accountNumber == "0" && this.checkAccountNumber)
            {
              this.checkAccountNumber = false; // only check the account number once
              // grab the first record in the result.records if it exists
              if(result.records.length > 0)
              {
                // check if there is an this.checkAccountNumber that has a value 
                if(result.records[0].ecommerceRequestId)
                {
                  this.accountNumber = result.records[0].ecommerceRequestId;
                  this.searchByRequest = true;
                  this.filterRequests(); 
                }
              }
            }
            this.requests = result.records;
            this.isLoading = false;
            this.hasValues = true;
            this.totalRecords = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  filterRequests() {
    this.filterBy = [];

    const filterByStatus = new EventLogFilterByFilterBy({
      property: EventLogFilterBy.AccountNumber,
      equal: this.accountNumber
    });

    if(this.accountNumber == "0")
    {
      const filterByStatus2 = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.ConfirmationCode,
        equal: this.confirmationCode
      });
      this.filterBy.push(filterByStatus2);
    }

    else{
      if(this.searchByRequest)
        {
          const filterByStatus2 = new EventLogFilterByFilterBy({
            property: EventLogFilterBy.RequestId,
            equal: this.accountNumber
          });
          this.filterBy.push(filterByStatus2);
        }
        else{
          this.filterBy.push(filterByStatus);
        }
    }

    const startDate = this.dateFormGroup.get('startDate').value;
    const endDate = this.dateFormGroup.get('endDate').value;

    if (startDate) {
      const filterByStartDate = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.StartDate,
        equal: startDate
      });
      this.filterBy.push(filterByStartDate);
    }

    if (endDate) {
      if (startDate && endDate < startDate) {
        this.snackBarService.ShowError('End date must be after start date');
        return;
      }
      const filterByEndDate = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.EndDate,
        equal: endDate
      });
      this.filterBy.push(filterByEndDate);
    }

    const requestTypes = this.selectedIds;
    if (requestTypes && requestTypes.length > 0) {
      const filterByRequestType = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.EventAction,
        in: requestTypes.join(',')
      });
      this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('selectedRequestType').value) {
      const filterByRequestType = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.EventType,
        equal: this.filterFormGroup.get('selectedRequestType').value
      });
      this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('selectedEventOrigin').value) {
      const filterByRequestType = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.EventOrigin,
        equal: this.filterFormGroup.get('selectedEventOrigin').value
      });
      this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('confirmationCode').value) {
      const filterByRequestType = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.ConfirmationCode,
        equal: this.filterFormGroup.get('confirmationCode').value
      });
      this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('paymentAmount').value) {
      const filterByRequestType = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.PaymentAmount,
        equal: this.filterFormGroup.get('paymentAmount').value
      });
      this.filterBy.push(filterByRequestType);
    }

    this.loadEventTypes();
  }

  onClearFiltersClicked() {
    this.filterFormGroup.patchValue({
      accountNumber: null,
      selectedRequestType: null,
      selectedEventOrigin: null,
      confirmationCode: null,
      paymentAmount: null
    });
    this.sortControl.setValue('desc'); // Reset to default
    this.sortBy = []; // Clear the existing sortBy array

    const sortByDaysOld = new EventLogSortBySortBy({
      property: EventLogSortBy.OccurredAt,
      descendingOrder: true,
    });

    this.sortBy.push(sortByDaysOld);
    this.dateFormGroup.patchValue({
      startDate: null,
      endDate: null
    });
    this.selectedIds = [];
    this.filterRequests();
  }

  getUserDisplay(event: EventLogViewModel): string {
    if(event.firstechUser)
    {
      return event.firstechUser;
    }

    if(event.eventType == EventType.FirstechTransaction || event.eventType == EventType.FirstechUser)
    {
      return 'FirsTech Guest';
    }

    if (event.csrPortalUserUniqueId) {
      return `${event.csrName} (CSR)`;
    } else if (event.impersonatedUserUniqueId) {
      if(event.csrName && event.csrName.length > 0)
      {
        return `${event.csrName} (CSR)  ${this.translate.instant("impersonating")} ${event.impersonatedUserEmail}`;
      }
      return `${event.myAccountUserEmail} impersonating ${event.impersonatedUserEmail}`;
    } else if (event.myAccountUserUniqueId) {
      return `${event.myAccountUserEmail}`;
    } else {
      if(event.eventAction == EventAction.EmailNotificationSent || event.eventAction == EventAction.EmailNotificationNotSent)
      {
        return this.translate.instant("email");
      }
      if (event.eventType == EventType.Payment)
        return this.translate.instant("payment");
      return this.translate.instant("guest");
    }
  }

  getEmailTooltip(event: EventLogViewModel): boolean
  {
    if(event.eventAction == EventAction.EmailNotificationNotSent)
    {
      return true;
    }
    return false;
  }

  showCopyEmail(event: EventLogViewModel): boolean {
    if(event.eventType == EventType.FirstechTransaction || event.eventType == EventType.FirstechUser)
      {
        return false;
      }

    if(this.getUserDisplay(event) == 'Guest' || this.getUserDisplay(event) == 'Payment' || this.getUserDisplay(event) == 'FirsTech Guest' || this.getUserDisplay(event) == 'Email')
      {
        return false;
      }  
      return true;
  }

  getSchedulePaymentDisplay(event: EventLogViewModel) : string {
    if(event.eventAction == EventAction.FuturePaymentScheduled)
    {
      if(event.isFutureDatedPaymentCancelled)
      {
        return '(Cancelled)';
      }
      if(event.isFutureDatedPaymentCompleted)
      {
        return '(Paid)';
      }
    }
    if(event.eventAction == EventAction.SuccessfulPayment)
    {
      if(event.isFutureDatedPaymentCancelled)
      {
        return '(Voided)';
      }
    }
    return '';
  }

  getCSREmail(event: EventLogViewModel): string {
    if (event.csrPortalUserUniqueId) {
      return event.csrPortalUserEmail;
    }
    if ((event.csrName && event.csrName.length > 0 ) && (event.myAccountUserEmail && event.myAccountUserEmail.length > 0 )) {
      return event.myAccountUserEmail;
    }
    return '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}