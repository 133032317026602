import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AccountsReceivableClient,
  ErpSourceSystem,
  PaymentExceptions,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { ClosePaymentExceptionDialogComponent, IClosePaymentExceptionDialogInputData } from "./confirm-close-exception-dialog/confirm-close-exception-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
  selector: "app-exception-details",
  templateUrl: "./exception-details.component.html",
  styleUrls: ["./exception-details.component.scss"],
})
export class ExceptionDetailsComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  displayedColumns: string[] = [
    "accountNumber",
    "invoiceNumber",
    "siteNumber",
    // "balance",
    "amount"
  ];

  isLoading: boolean;
  confirmationCode : string = "";
  paymentException: PaymentExceptions;

  constructor(
    private accountsReceivableClient: AccountsReceivableClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.confirmationCode = this.route.snapshot.params["id"];
    this.loadPaymentExceptions();
  }

  loadPaymentExceptions(){
    this.subscription.add(
      this.accountsReceivableClient.lookupPaymentException(this.confirmationCode)
      .subscribe(
        (result) => {
          this.paymentException = result.result;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  goBack()
  {
    // if there are no query params, then just go back to the previous page
    if(Object.keys(this.route.snapshot.queryParams).length === 0)
    {
        // simulate the user clicking the back button on their browser
        window.history.back();
    }
    const queryParams = this.route.snapshot.queryParams;

    if (this.router.url.includes('closed-payment-exceptions')) {
      this.router.navigate(['/accounts-receivable/closed-payment-exceptions'], { queryParams });
      return;
    }
    this.router.navigate(['/accounts-receivable/payment-exceptions'], { queryParams });
  }

  getErpSourceSystem() 
  {
    if(this.paymentException.paymentTypeCode.includes("NAVU"))
    {
      return ErpSourceSystem.GFLOne;
    }
    else if(this.paymentException.paymentTypeCode.includes("TRUX"))
    {
      return ErpSourceSystem.Trux;
    }
    else if(this.paymentException.paymentTypeCode.includes("TOWER"))
    {
      return ErpSourceSystem.Tower;
    }
    return undefined;
  }

  closeExceptionClicked()
  {
    this.isLoading = true;
        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = "600px";
        dialogConfig.maxHeight = "800px";


    
        const data: IClosePaymentExceptionDialogInputData = {
          confirmationCode : this.confirmationCode,
          erpSourceSystem: this.getErpSourceSystem()  
        };
    
        dialogConfig.data = data;
        this.subscription.add(
          this.dialog
            .open(ClosePaymentExceptionDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((response) => {
              if (response) {
                this.isLoading = false;
                this.snackBarService.ShowSuccess("Exception Closed Successfully");
                // reload the page
                this.loadPaymentExceptions();
              }
            })
        );
        this.isLoading = false;
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
