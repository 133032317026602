import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from "src/app/shared/auth/auth.service";


@Component({
  selector: 'app-my-account-transfer-ownership-selector',
  templateUrl: './my-account-transfer-ownership-selector-page.component.html',
  styleUrls: ['./my-account-transfer-ownership-selector-page.component.scss'],
})
export class MyAccountTransferOwnershipSelectorComponent implements OnInit, OnDestroy {

    isAllowedToMassTransfer = false;
    isAllowedToTransfer = false;
    massSelected = false;
    normalSelected = false;

  subscription = new Subscription();
  isLoading = false;

  toolDescription = "This tool is meant to transfer ownership of a MyAccount account from one user profile to another. \n To transfer an account from one owner to another user that is already linked to the account, we can enter the account number and select the new owner. \n To transfer multiple accounts from one owner to another user, we can enter the email address of the old owner. Then select which accounts will be transferred and then enter the email address of the new owner.";

  constructor(
    private authService: AuthService,
  ) {}

    ngOnInit(){
        this.subscription
        .add(
          this.authService.currentUser
            .subscribe(user => {
              if(user) {
                // this.isAllowedToMassTransfer = user.isAllowedToMassTransferOwnership;
                // this.isAllowedToTransfer = user.isAllowedToTransferOwnership;
              }
            })
        );
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  

  onMassTransferClicked()
  {
    this.massSelected = true;
  }

  onTransferClicked()
  {
    this.normalSelected = true;
  }
}
