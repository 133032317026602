import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AccountsReceivableClient, ClosePaymentExceptionCommand, ErpSourceSystem } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

export interface IClosePaymentExceptionDialogInputData {
  confirmationCode: string;
  erpSourceSystem: ErpSourceSystem | undefined;
}

@Component({
  selector: 'app-close-exception-dialog',
  templateUrl: './confirm-close-exception-dialog.component.html',
  styleUrls: ['./confirm-close-exception-dialog.component.scss']
})
export class ClosePaymentExceptionDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  filterFormGroup = new FormGroup({
    emailFormControl: new FormControl(null),
  });

  isLoading = false;

  showNavuText = false;
  showTruxText = false;
  showTowerText = false;
  showDefaultText = false;

  constructor(
    private snackBarService: SnackBarService,
    private dialog: MatDialogRef<ClosePaymentExceptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IClosePaymentExceptionDialogInputData,
    private arClient: AccountsReceivableClient
  ) {}

  ngOnInit() {
    if (this.data.erpSourceSystem === ErpSourceSystem.GFLOne) {
      this.showNavuText = true;
    } else if (this.data.erpSourceSystem === ErpSourceSystem.Trux) {
      this.showTruxText = true;
    } else if (this.data.erpSourceSystem === ErpSourceSystem.Tower) {
      this.showTowerText = true;
    }
    else
    {
      this.showDefaultText = true;
    }

  }

  onEmailClicked() {
    this.isLoading = true;

    // check if the emailFormControl has a value
    if (!this.filterFormGroup.get('emailFormControl').value)
      {
      this.isLoading = false;
      this.snackBarService.ShowError('Please enter a valid posted id');
      return;
    }

    const request: ClosePaymentExceptionCommand = new ClosePaymentExceptionCommand({
      confirmationCode: this.data.confirmationCode,
      postedId: this.filterFormGroup.get('emailFormControl').value
    });

    this.subscription.add(
      this.arClient.closePaymentException(request).subscribe(
        (result) => {
          this.isLoading = false;
          if (result.isSuccess) {
            this.snackBarService.ShowSuccess('Payment Exception Closed Successfully');
            this.dialog.close(true);
          } else {
            this.snackBarService.ShowError(result.errorMessage);
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}