import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BusinessUnitDetailComponent } from './features/business-unit/business-unit-detail/business-unit-detail.component';
import { BusinessUnitLookupComponent } from './features/business-unit/business-unit-lookup/business-unit-lookup.component';
import { CreateBusinessUnitComponent } from './features/business-unit/create-business-unit/create-business-unit.component';
import { ErrorHandlingComponent } from './features/error-handling/error-handling/error-handling.component';
import { CreateFacilityComponent } from './features/facility/create-facility/create-facility.component';
import { FacilityDetailComponent } from './features/facility/facility-detail/facility-detail.component';
import { FacilityLookupComponent } from './features/facility/facility-lookup/facility-lookup.component';
import { MyAccountFusionAccountLookupComponent } from './features/fusion/my-account-fusion-account-lookup/my-account-fusion-account-lookup.component';
import { MyAccountFindAccountsComponent } from './features/users/my-account-find-accounts/my-account-find-accounts.component';
import { CreateGroupPageComponent } from './features/groups/create-group-page/create-group-page.component';
import { EditGroupPageComponent } from './features/groups/edit-group-page/edit-group-page.component';
import { GroupListPageComponent } from './features/groups/group-list-page/group-list-page.component';
import { MyAccountNotificationLookupComponent } from './features/notification/my-account-notification-lookup/my-account-notification-lookup.component';
import { MyAccountNotificationComponent } from './features/notification/my-account-notification/my-account-notification.component';
import { RecordSyncComponent } from './features/record-sync/record-sync.component';
import { RequestDetailsPageComponent } from './features/requests/request-details-page/request-details-page.component';
import { RequestListPageComponent } from './features/requests/request-list-page/request-list-page.component';
import { RequestStatsPageComponent } from './features/requests/request-stats-page/request-stats-page.component';
import { ReportsPageComponent } from './features/reports/reports-page/reports-page.component';
import { CreateUserPageComponent } from './features/users/create-user-page/create-user-page.component';
import { MyAccountAccountLookupComponent } from './features/users/my-account-account-lookup/my-account-account-lookup.component';
import { MyAccountCreateManagedAutopayAccountComponent } from './features/users/my-account-create-managed-autopay-account/my-account-create-managed-autopay-account.component';
import { MyAccountCustomerFinderComponent } from './features/users/my-account-customer-finder/my-account-customer-finder.component';
import { LinkListComponent } from './features/csr-link/link-list/link-list.component';
import { ExceptionListComponent } from './features/ar-tools/exception-list/exception-list.component';
import { ClosedExceptionListComponent } from './features/ar-tools/closed-exception-list/closed-exception-list.component';
import { ExceptionDetailsComponent } from './features/ar-tools/exception-details/exception-details.component';
import { LinkDetailsComponent } from './features/csr-link/link-details/link-details.component';
import { PaymentTypeLinkSelectorComponent } from './features/csr-link/payment-selector/payment-selector.component';
import { MyAccountDeleteAzureProfileComponent } from './features/users/my-account-delete-azure-profile/my-account-delete-azure-profile.component';
import { MyAccountDeleteEbillingComponent } from './features/users/my-account-delete-ebilling/my-account-delete-ebilling.component';
import { MyAccountPageComponent } from './features/users/my-account-page/my-account-page.component';
import { MyAccountPayMyBillDataValidationComponent } from './features/users/my-account-paymybill-datavalidation/my-account-paymybill-datavalidation.component';
import { MyAccountTransferOwnershipSelectorComponent } from './features/users/my-account-transfer-ownership-selector-page/my-account-transfer-ownership-selector-page.component';
import { UserEditPageComponent } from './features/users/user-edit-page/user-edit-page.component';
import { UserListPageComponent } from './features/users/user-list-page/user-list-page.component';
import { CreatePermissionGroupPageComponent } from './features/users/create-permission-group-page/create-permission-group-page.component';
import { EditPermissionGroupPageComponent } from './features/users/edit-permission-group-page/edit-permission-group-page.component';
import { PermissionGroupListPageComponent } from "./features/users/permission-group-list-page/permission-group-list-page.component";
import { AuthGuard } from './shared/auth/auth.guard';
import { AreasDetailsPageComponent } from "./features/ecommerce/areas-details/areas-details.component";
import { AreasLookupPageComponent } from "./features/ecommerce/areas-lookup/areas-lookup.component";
import { BillingCyclesDetailsPageComponent } from "./features/ecommerce/billing-cycles-details/billing-cycles-details.component";
import { BillingCyclesLookupPageComponent } from "./features/ecommerce/billing-cycles-lookup/billing-cycles-lookup.component";
import { BlacklistLookupPageComponent } from "./features/ecommerce/blacklist-lookup/blacklist-lookup.component";
import { BlacklistDetailsPageComponent } from "./features/ecommerce/blacklist-details/blacklist-details.component";
import { FeeDetailsPageComponent } from "./features/ecommerce/fees-details/fees-details.component";
import { FeesLookupPageComponent } from "./features/ecommerce/fees-lookup/fees-lookup.component";
import { PromoCodesDetailsPageComponent } from "./features/ecommerce/promo-codes-details/promo-codes-details.component";
import { PromoCodesLookupPageComponent } from "./features/ecommerce/promo-codes-lookup/promo-codes-lookup.component";
import { ServiceCodesDetailsPageComponent } from "./features/ecommerce/service-codes-details/service-codes-details.component";
import { ServiceCodesLookupPageComponent } from "./features/ecommerce/service-codes-lookup/service-codes-lookup.component";
import { ServiceFrequencyDetailsPageComponent } from "./features/ecommerce/service-frequency-details/service-frequency-details.component";
import { ServiceFrequencyLookupPageComponent } from "./features/ecommerce/service-frequency-lookup/service-frequency-lookup.component";
import { ServiceTypesDetailsPageComponent } from "./features/ecommerce/service-types-details/service-types-details.component";
import { ServiceTypesLookupPageComponent } from "./features/ecommerce/service-types-lookup/service-types-lookup.component";
import { AreaCreatePageComponent } from './features/ecommerce/area-create-page/area-create-page.component';
import { BillingCycleCreatePageComponent } from './features/ecommerce/billing-cycle-create-page/billing-cycle-create-page.component';
import { FeeCreatePageComponent } from './features/ecommerce/fee-create-page/fee-create-page.component';
import { ServiceTypeCreatePageComponent } from './features/ecommerce/service-type-create-page/service-type-create-page.component';
import { ServiceFrequencyCreatePageComponent } from './features/ecommerce/service-frequency-create-page/service-frequency-page.component';
import { ServiceCodeCreatePageComponent } from './features/ecommerce/service-code-create-page/service-code-create-page.component';
import { PromoCodeCreatePageComponent } from './features/ecommerce/promo-code-create-page/promo-code-create-page.component';
import { BlacklistCreatePageComponent } from './features/ecommerce/blacklist-create-page/blacklist-create-page.component';

const appRoutes: Routes = [
  { path: "requests", component: RequestListPageComponent, canActivate: [MsalGuard] },
  {
    path: "admin/business_units",
    component: BusinessUnitLookupComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewBusinessUnits: true }
  },
  {
    path: "admin/facility",
    component: FacilityLookupComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewFacilities: true }
  },
  {
    path: "admin/facility/:id/:facilityId",
    component: FacilityDetailComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewFacilities: true }
  },
  {
    path: "admin/facility/create",
    component: CreateFacilityComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateFacility: true }
  },
  {
    path: "admin/business_units/detail/:buId",
    component: BusinessUnitDetailComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewBusinessUnits: true }
  },
  {
    path: "admin/business_units/create",
    component: CreateBusinessUnitComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateBusinessUnit: true }
  },
  {
    path: "admin/users",
    component: UserListPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewAllUsers: true },
  },
  {
    path: "my-account/create",
    component: MyAccountPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateMyAccountUser: true },
  },
  {
    path: "my-account/notification/lookup",
    component: MyAccountNotificationLookupComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewNotifications: true },
  },
  {
    path: "my-account/notification/create",
    component: MyAccountNotificationComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewNotifications: true },
  },
  // {
  //   path: "my-account/transfer",
  //   component: MyAccountTransferOwnershipSelectorComponent,
  //   canActivate: [MsalGuard, AuthGuard],
  //   data: { isAllowedToTransferOwnership: true },
  // },
  {
    path: "my-account/autopay-account/lookup",
    component: MyAccountFusionAccountLookupComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToDeleteFusionAutoPay: true },
  },
  // {
  //   path: "my-account/pay-my-bill-data-validation",
  //   component: MyAccountPayMyBillDataValidationComponent,
  //   canActivate: [MsalGuard, AuthGuard],
  //   data: { isAllowedToUsePayMyBillValidation: true },
  // },
  {
    path: "reports/requests/stats",
    component: RequestStatsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewRequestStats: true },
  },
  {
    path: "reports",
    component: ReportsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewReports: true },
  },
  {
    path: "requests/:requestId",
    component: RequestDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewRequests: true },
  },
  {
    path: "admin/users/create",
    component: CreateUserPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateUser: true },
  },
  {
    path: "admin/users/:userId",
    component: UserEditPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewAllUsers: true },
  },
  {
    path: "admin/groups",
    component: GroupListPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewGroups: true },
  },
  {
    path: "admin/groups/create",
    component: CreateGroupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateGroups: true },
  },
  {
    path: "admin/groups/:groupId",
    component: EditGroupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewGroups: true },
  },
  {
    path: "admin/permissions/groups/create",
    component: CreatePermissionGroupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreatePermissionGroups: true },
  },
  {
    path: "admin/permissions/groups/:groupId",
    component: EditPermissionGroupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditPermissionGroups: true },
  },
  {
    path: "admin/permissions/groups",
    component: PermissionGroupListPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewPermissionGroups: true },
  },
  // {
  //   path: "my-account/create/managed-account",
  //   component: MyAccountCreateManagedAutopayAccountComponent,
  //   canActivate: [MsalGuard, AuthGuard],
  //   data: { isAllowedToCreateManagedAutoPayMyAccountUser: true },
  // },
  {
    path: "my-account/account-lookup",
    component: MyAccountAccountLookupComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseAccountLookup: true },
  },
  {
    path: "my-account/delete-profile",
    component: MyAccountDeleteAzureProfileComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToDeleteAzureProfile: true },
  },
  // {
  //   path: "my-account/delete-ebilling",
  //   component: MyAccountDeleteEbillingComponent,
  //   canActivate: [MsalGuard, AuthGuard],
  //   data: { isAllowedToDeleteEbilling: true },
  // },
  {
    path: "finder",
    component: MyAccountCustomerFinderComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseCustomerFinder: true }
  },
  {
    path: "payment-link",
    component: LinkListComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseSecureCheckoutLinks: true }
  },
  {
    path: "accounts-receivable/payment-exceptions",
    component: ExceptionListComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewARTools: true }
  },
  {
    path: "accounts-receivable/closed-payment-exceptions",
    component: ClosedExceptionListComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewARTools: true }
  },
  {
    path: "accounts-receivable/payment-exceptions/:id",
    component: ExceptionDetailsComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewARTools: true }
  },
  {
    path: "accounts-receivable/closed-payment-exceptions/:id",
    component: ExceptionDetailsComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewARTools: true }
  },
  {
    path: "payment-link/view/:id",
    component: LinkDetailsComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseSecureCheckoutLinks: true }
  },
  {
    path: "payment-link/create",
    component: PaymentTypeLinkSelectorComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseSecureCheckoutLinks: true }
  },

  {
    path: "admin/errors",
    loadChildren: () => import('src/app/features/error-handling/error-handling.module').then(m => m.ErrorHandlingModule),
    component: ErrorHandlingComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewErrorHandling: true },
  },
  {
    path: "my-account/record-sync",
    component: RecordSyncComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseRecordSync: true },
  },
  {
    path: "my-account/user-lookup",
    component: MyAccountFindAccountsComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToUseEmailLookup: true },
  },
  {
    path: "ecommerce/areas",
    component: AreasLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewEcommerceAreas: true },
  },
  {
    path: "ecommerce/areas/edit/:id",
    component: AreasDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditEcommerceAreas: true },
  },
  {
    path: "ecommerce/blacklist",
    component: BlacklistLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewEcommerceBlacklist: true },
  },
  {
    path: "ecommerce/blacklist/edit/:id",
    component: BlacklistDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditEcommerceBlacklist: true },
  },
  {
    path: "ecommerce/blacklist/create",
    component: BlacklistCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateEcommerceBlacklist: true },
  },
  {
    path: "ecommerce/fees",
    component: FeesLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewEcommerceFees: true },
  },
  {
    path: "ecommerce/fees/edit/:id",
    component: FeeDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditEcommerceFees: true },
  },

  {
    path: "ecommerce/promo-codes",
    component: PromoCodesLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewPromoCodes: true },
  },
  {
    path: "ecommerce/promo-codes/edit/:id",
    component: PromoCodesDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditPromoCodes: true },
  },
  {
    path: "ecommerce/promo-codes/create",
    component: PromoCodeCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreatePromoCodes: true },
  },
  {
    path: "ecommerce/service-codes",
    component: ServiceCodesLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewServiceCodes: true },
  },
  {
    path: "ecommerce/service-codes/edit/:id",
    component: ServiceCodesDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditServiceCodes: true },
  },
  {
    path: "ecommerce/service-codes/create",
    component: ServiceCodeCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateServiceCodes: true },
  },

  {
    path: "ecommerce/service-frequencies",
    component: ServiceFrequencyLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewServiceFrequencies: true },
  },
  {
    path: "ecommerce/service-frequencies/edit/:id",
    component: ServiceFrequencyDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditServiceFrequencies: true },
  },
  {
    path: "ecommerce/service-frequencies/create",
    component: ServiceFrequencyCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateServiceFrequencies: true },
  },

  {
    path: "ecommerce/service-types",
    component: ServiceTypesLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewServiceTypes: true },
  },
  {
    path: "ecommerce/service-types/create",
    component: ServiceTypeCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateServiceTypes: true },
  },
  {
    path: "ecommerce/service-types/edit/:id",
    component: ServiceTypesDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditServiceTypes: true },
  },

  {
    path: "ecommerce/billing-cycles",
    component: BillingCyclesLookupPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToViewBillingCycles: true },
  },
  {
    path: "ecommerce/billing-cycles/edit/:id",
    component: BillingCyclesDetailsPageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToEditBillingCycles: true },
  },
  {
    path: "ecommerce/areas/create",
    component: AreaCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateEcommerceAreas: true },
  },
  {
    path: "ecommerce/billing-cycles/create",
    component: BillingCycleCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateBillingCycles: true },
  },
  {
    path: "ecommerce/fees/create",
    component: FeeCreatePageComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: { isAllowedToCreateEcommerceFees: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
