import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MyAccountAccountWithUserViewModel, MyAccountAutopayViewModel, MyAccountContainsFusionAccountWithAutoPayViewModel, MyAccountFusionAccountViewModel, MyAccountFusionClient, MyAccountUsersClient } from 'src/app/apis/customer-care-api.generated';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { ConfirmFusionProfileDeleteDialogComponent, IConfirmFusionProfileDeleteDialog } from './confirm-fusion-profile-delete-dialog/confirm-fusion-profile-delete-dialog.component';

@Component({
  selector: 'app-my-account-fusion-account-lookup',
  templateUrl: './my-account-fusion-account-lookup.component.html',
  styleUrls: ['./my-account-fusion-account-lookup.component.css']
})
export class MyAccountFusionAccountLookupComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  constructor(private snackBarService: SnackBarService,
    private myAccountFusionClient: MyAccountFusionClient,
    private dialog: MatDialog,
    private authService: AuthService) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toolDescription = "This tool is meant to find the Autopay Account related to the inputted Account Number. \n This tool will then show the Account information. \n We can then choose to delete the Autopay for the Account."

  isLoading = false;

  myAccountFusionAccount: MyAccountAutopayViewModel;
  hasAutopayButNotInMyAccount = false;

  get isAllowedToDeleteFusionAccount(): boolean {
    return (
      this.authService.isAllowedToDeleteFusionAutoPay
    );
  }

  get fusionAccountFound() {
    return (
      this.myAccountFusionAccount 
    )
  }

  accountNumber = new FormControl("", Validators.minLength(9));

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0 && this.accountNumber.valid;
  }

  displayedFusionAccountColumns: string[] = [
    "accountNumber",
    "referenceNumber",
    "scheduleType",
    "methodType",
    "paymentType",
    "daysBefore",
    "smsConfirmationPhoneNumber",
    "siteNumber",
    "paymentMethod",
    "createdDate",
    "createdByName",
    "deleteProfile"
];

  deleteProfile(element: MyAccountAutopayViewModel) {
    var profileId: number = +element.referenceNumber;

    const dialogConfig = new MatDialogConfig();

    const data: IConfirmFusionProfileDeleteDialog = {
      accountNumber: element.accountNumber,
      fusionProfileId: element.referenceNumber.toString()
    }

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";
    dialogConfig.data = data;

    this.subscription.add(
      this.dialog
        .open(ConfirmFusionProfileDeleteDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isLoading = true;
            this.myAccountFusionClient
              .deleteFusionEnrollmentWithAutoPay(element.uniqueId)
              .subscribe(
                x => {
                  this.isLoading = false;
                  this.snackBarService.ShowSuccess("Autopay Account deleted!");
                  this.myAccountFusionAccount = null;
                  this.accountNumber.patchValue("");
                },
                (error) => {
                  this.isLoading = false;
                  this.snackBarService.ShowError(
                    error
                  );
                }
              )
          }
        }));
  }

  search() {
    this.myAccountFusionAccount = null;
    this.isLoading = true;
    this.subscription.add(
      this.myAccountFusionClient
        .searchForMyAccountFusionEnrollmentWithAutoPay(this.accountNumber.value.trim())
        .subscribe(
          (x) => {
            this.hasAutopayButNotInMyAccount = x.result.hasPaymentusAutopayRecord;
            this.myAccountFusionAccount = x.result.autopayRecord;
            this.isLoading = false;
            if(this.hasAutopayButNotInMyAccount && this.myAccountFusionAccount === null) {
              this.snackBarService.ShowError("This account has an Autopay enrolled in Paymentus, but no record exists in MyAccount. Please create a support ticket with IT to resolve this problem.");
            }
          },
          (error) => {
            this.isLoading = false;
            this.snackBarService.ShowError(
              error
            );
          }
        ));
  }

  ngOnInit() {
  }

}
