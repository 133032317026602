import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessUnitViewModel, LookupClient, MyAccountNotificationClient, NotificationColorEnum, SubmitNotificationCommand, UsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-account-notification',
  templateUrl: './my-account-notification.component.html',
  styleUrls: ['./my-account-notification.component.css']
})
export class MyAccountNotificationComponent implements OnInit, OnDestroy {

  @Input() createActive: boolean;

  private subscription: Subscription = new Subscription();

  notificationByBusinessUnit = new FormControl();
  businessUnitId = new FormControl('');
  accountNumber = new FormControl(null);
  siteNumber = new FormControl(null);

  notificationFormGroup = new FormGroup({
    businessUnitIds: new FormArray([]),
    accountNumbers: new FormArray([]),
    siteNumbers: new FormArray([]),
    startDate: new FormControl(new Date()),
    subject: new FormControl(null, Validators.required),
    message: new FormControl(null, Validators.required),
    color: new FormControl(NotificationColorEnum.Orange)
  });
  businessUnits: BusinessUnitViewModel[];
  isBusinessUnitsLoading: boolean;
  notificationColors = Object.values(NotificationColorEnum);

  isLoading: boolean = false;


  get businessUnitIdValues() {
    return this.notificationFormGroup.controls.businessUnitIds.value.join(',');
  }

  get accountNumberValues() {
    return this.notificationFormGroup.controls.accountNumbers.value.join(',');
  }

  get siteNumberValues() {
    return this.notificationFormGroup.controls.siteNumbers.value.join(',');
  }

  get isValidNotification() {
    return this.notificationFormGroup.controls.subject.valid
      && this.notificationFormGroup.controls.message.valid
      && (this.notificationFormGroup.controls.businessUnitIds.value.length > 0
        || this.notificationFormGroup.controls.accountNumbers.value.length > 0
        || this.notificationFormGroup.controls.siteNumbers.value.length > 0);
  }

  constructor(
    private userClient: UsersClient,
    private snackBarService: SnackBarService,
    private router: Router,
    private notificationClient: MyAccountNotificationClient,) {
      
    this.getBusinessUnits();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }
  
  addSiteNumber = () => {
    if(this.siteNumber.value == null || this.siteNumber.value == undefined || this.siteNumber.value == '')
      return false;
    this.notificationFormGroup.controls.siteNumbers.value.push(this.siteNumber.value);
    this.siteNumber.patchValue(null);
  };
  
  addAccountNumber = () => {
    if(this.accountNumber.value == null || this.accountNumber.value == undefined || this.accountNumber.value == '')
      return false;
    this.notificationFormGroup.controls.accountNumbers.value.push(this.accountNumber.value);
    this.accountNumber.patchValue(null);
  };
  
  addBusinessId = () => {
    if(this.businessUnitId.value == null || this.businessUnitId.value == undefined || this.businessUnitId.value == '')
      return false;
    this.notificationFormGroup.controls.businessUnitIds.value.push(this.businessUnitId.value);
    this.businessUnitId.patchValue(null);
  };

  getBusinessUnits() {
    this.isBusinessUnitsLoading = true;
    this.subscription.add(
      this.userClient.getCurrentUserBusinessUnits().subscribe(
        (result) => {
          this.businessUnits = result.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
        },
        (err) => {
          if(err.status == 403) {
            this.snackBarService.ShowError(
              "User ID does not exist in Portal. Please create  ticket with IT helpdesk.");
          }
          else {
            console.error(err);
            this.snackBarService.ShowError("Business Units Error: " + err);
          }
        },
        () => {
          this.isBusinessUnitsLoading = false;
        }
      )
    );
  }

  notificationByBusinessUnitChange = () => {
    this.notificationFormGroup.controls.businessUnitIds.patchValue([]);
    this.notificationFormGroup.controls.accountNumbers.patchValue([]);
    this.notificationFormGroup.controls.siteNumbers.patchValue([]);
  }
  

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number => 
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);
    
  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.code;
  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  submit = () => {
    var command: SubmitNotificationCommand = new SubmitNotificationCommand ();
    command.businessUnitIds = this.notificationFormGroup.controls.businessUnitIds.value;
    command.accountIds = this.notificationFormGroup.controls.accountNumbers.value;
    command.siteIds = this.notificationFormGroup.controls.siteNumbers.value;
    command.message = this.notificationFormGroup.controls.message.value;
    command.title = this.notificationFormGroup.controls.subject.value;
    command.notificationDate = this.notificationFormGroup.controls.startDate.value;
    command.notificationColor = this.notificationFormGroup.controls.color.value;
    
    this.isLoading = true;
    this.subscription.add(
      this.notificationClient
        .submitNotificationCommand(command)
        .subscribe(
          x => {
            this.snackBarService.ShowSuccess("Notification submitted");
            this.notificationByBusinessUnit.reset();
            this.businessUnitId.reset();
            this.accountNumber.reset();
            this.siteNumber.reset();
            this.notificationFormGroup.reset();
            this.notificationFormGroup.controls.startDate.patchValue(new Date());
            this.createActive = false;
            this.router.navigate(['/my-account/notification/lookup']);
          },
          (error) => {
            this.snackBarService.ShowError(error);
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        )
    );
  }
}
